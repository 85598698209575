import { Icon } from '@nrk/yr-icons';
import classNames from 'classnames';
import { Text } from '../Text/Text';

interface IProps {
  testId?: string;
  align?: 'left' | 'center' | 'right';
  children: React.ReactNode | string;
  className?: string;
  sticky?: boolean;
  sortDirection?: 'none' | 'ascending' | 'descending';
  onSort?: () => void;
}

export function FluidTable__Heading(props: IProps) {
  const { align = 'center', children, className, testId, sticky = false, sortDirection, onSort } = props;

  // Because we use `display: flex` on `.fluid-table__cell-content` to position its content
  // we need to ensure it has only one child so we won't accidentally lose any whitespace
  // between its children in case of content such as `['<span>I dag</span>', ' cm]`.
  const content = <span>{children}</span>;

  let sortableIcon: React.ReactNode | undefined = undefined;
  if (sortDirection != null) {
    if (sortDirection === 'none') {
      sortableIcon = <Icon className="fluid-table__sort-icon" id="icon-sortable" size={2} />;
    } else {
      sortableIcon = (
        <Icon className="fluid-table__sort-icon" id="icon-sort" size={2} data-sort-direction={sortDirection} />
      );
    }
  }

  return (
    <Text
      as="th"
      size="5"
      color="secondary"
      data-testid={testId}
      className={classNames(
        className,
        'fluid-table__cell',
        'fluid-table__cell--heading',
        `fluid-table__cell--align-${align}`,
        {
          'fluid-table__cell--sticky': sticky
        }
      )}
      aria-sort={sortDirection}
    >
      {onSort != null ? (
        <button className="fluid-table__sort-button" onClick={onSort}>
          <span className="fluid-table__cell-content">
            {content} {sortableIcon}
          </span>
        </button>
      ) : (
        <span className="fluid-table__cell-content">
          {content} {sortableIcon}
        </span>
      )}
    </Text>
  );
}
