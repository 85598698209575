import { useTranslate } from '../../lib/hooks/useTranslate';
import { Translate } from '../Translate/Translate';
import { IFluidTableColumn } from './FluidTable';

interface IProps {
  tableCaption: string;
  columns: IFluidTableColumn[];
  isSortable: boolean;
  sort?: { columnIndex: number; direction: 'ascending' | 'descending' };
}

export function FluidTable__Caption(props: IProps) {
  const { tableCaption, columns, isSortable, sort } = props;

  const translate = useTranslate();

  if (isSortable === false) {
    return <caption className="fluid-table__caption">{tableCaption}</caption>;
  }

  return (
    <caption className="fluid-table__caption">
      {sort != null ? (
        <Translate
          id="fluidTable/sortDirectionAriaLabel/template"
          values={{
            tableDescription: tableCaption,
            sortDescription: (
              /*
                      Notify screen reader users that the table sort has finished.
                      We need to use a unique key based on column index and direction because we need a new element
                      whenever the sorted column or direction changes otherwise screen readers will not announce the change.
                      Using a unique key makes React create a new span element instead of simply changing its content.
                    */
              <span key={`${sort.columnIndex}/${sort.direction}`} aria-live="assertive">
                <Translate
                  id={
                    sort.direction === 'ascending'
                      ? 'fluidTable/sortDirectionAriaLabel/ascending'
                      : 'fluidTable/sortDirectionAriaLabel/descending'
                  }
                  values={{ columnName: columns[sort.columnIndex].heading }}
                />
              </span>
            )
          }}
        />
      ) : (
        tableCaption
      )}{' '}
      {translate('fluidTable/isSortableCaption')}
    </caption>
  );
}
