// The display values for precipitation probability is specified here: https://confluence.nrk.no/pages/viewpage.action?pageId=84115628
export function getPrecipitationProbability(value: number) {
  if (value < 2) {
    return {
      abbreviation: 'lessThan',
      value: '2'
    };
  }

  if (value >= 2 && value < 2.5) {
    return { value: '2' };
  }

  if (value >= 97.5 && value <= 98) {
    return { value: '98' };
  }

  if (value > 98) {
    return {
      abbreviation: 'greaterThan',
      value: '98'
    };
  }

  // We round to nearest 5% if value is between 2.5 and 7.5 or between 92.5 and 97.5
  if (value >= 92.5 || value < 7.5) {
    return { value: `${Math.round(value / 5) * 5}` };
  }

  // Rest of the values is rounded to nearest 10%
  return { value: `${Math.round(value / 10) * 10}` };
}
