import { sentenceCase } from '../../lib/string';
import { getWindDescriptionWithDirection } from '../../lib/helpers/windDescriptionHelpers';
import { useTranslate } from '../../lib/hooks/useTranslate';

interface IProps {
  direction: number;
  speed?: number;
  gust?: number;
}

export function WindDescription(props: IProps) {
  const { direction, speed = 0, gust } = props;
  const translate = useTranslate();

  let windDescription = getWindDescriptionWithDirection(speed, direction, translate, gust);

  return <span className="wind-description">{sentenceCase(windDescription)}</span>;
}
