export function sortCompareFunctionWithUndefined(a?: number | string, b?: number | string) {
  if (a != null && b != null) {
    if (a < b) {
      return -1;
    }

    if (a > b) {
      return 1;
    }
  }

  if (a == null && b != null) {
    return -1;
  }

  if (a != null && b == null) {
    return 1;
  }

  return 0;
}
