import classNames from 'classnames';

interface IProps {
  testId?: string;
  align?: 'left' | 'center' | 'right';
  children: React.ReactNode | string;
  className?: string;
  sticky?: boolean;
  href?: string;
}

export function FluidTable__Cell(props: IProps) {
  const { align = 'center', children, className, href, testId, sticky = false } = props;

  // Because we use `display: flex` on `.fluid-table__cell-content` to position its content
  // we need to ensure it has only one child so we won't accidentally lose any whitespace
  // between its children in case of content such as `['<span>I dag</span>', ' cm]`.
  const content = <span>{children}</span>;

  return (
    <td
      data-testid={testId}
      className={classNames(className, 'fluid-table__cell', `fluid-table__cell--align-${align}`, {
        'fluid-table__cell--sticky': sticky
      })}
    >
      {href != null ? (
        <a className="fluid-table__cell-content fluid-table__cell-content--link" href={href}>
          {content}
        </a>
      ) : (
        <span className="fluid-table__cell-content">{content}</span>
      )}
    </td>
  );
}
