import { getPrecipitationProbability } from '../../modelHelpers/getPrecipitationProbability';
import { useTranslate } from '../../lib/hooks/useTranslate';

interface IProps {
  probability: number;
}

export function PrecipitationProbability(props: IProps) {
  const { probability } = props;
  const translate = useTranslate();

  const { value, abbreviation } = getPrecipitationProbability(probability);

  return (
    <span role="text">
      {abbreviation ? (
        <abbr title={translate(`grammar/${abbreviation}`)}>{abbreviation === 'greaterThan' ? '> ' : '< '}</abbr>
      ) : null}
      {value}
    </span>
  );
}
