import time from '@nrk/yr-time';
import classNames from 'classnames';
import { useMemo } from 'react';
import { IForecastIntervalsGroupedByDay } from '../../model/forecast';
import { isForecastLongInterval } from '../../lib/helpers/intervals';
import { getWindSpeedWithDirectionAndGust } from '../../lib/helpers/windDescriptionHelpers';
import { useTimeLocale } from '../../lib/hooks/useTimeLocale';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { FluidTable, IFluidTableColumn } from '../FluidTable/FluidTable';
import { Loader } from '../Loader/Loader';
import { MinMaxPrecipitation } from '../MinMaxPrecipitation/MinMaxPrecipitation';
import { PrecipitationProbability } from '../PrecipitationProbability/PrecipitationProbability';
import { WeatherSymbol } from '../WeatherSymbol/WeatherSymbol';
import { Temperature } from '../Temperature/Temperature';
import { Wind } from '../Wind/Wind';
import { WindDescription } from '../WindDescription/WindDescription';
import './HourlyWeatherTable.scss';
import { convertSymbolKeyToId } from '@nrk/yr-weather-symbols';
import { Abbreviation } from '../Abbreviation/Abbreviation';
import { Text } from '../Text/Text';

interface IProps {
  type: 'simple' | 'detailed';
  tableCaption: string;
  intervalsGroupedByDay: IForecastIntervalsGroupedByDay;
}

export function HourlyWeatherTable(props: IProps) {
  const { type, tableCaption, intervalsGroupedByDay } = props;

  const translate = useTranslate();
  const timeLocale = useTimeLocale();

  const hasGust = intervalsGroupedByDay.intervals.some(interval => interval.wind.gust !== undefined);
  const hasPrecipitationProbability = intervalsGroupedByDay.intervals.some(
    interval => interval.precipitation.probability !== undefined
  );

  const weatherTableColumns = useMemo(() => {
    const columns: IFluidTableColumn[] = [];

    columns.push({
      testId: 'time',
      visible: true,
      align: 'left',
      heading: (
        <Abbreviation title={translate('terminology/time/long')} abbreviation={translate('terminology/time/short')} />
      )
    });

    columns.push({
      testId: 'symbol',
      visible: true,
      align: 'center',
      heading: (
        <Abbreviation
          title={translate('terminology/weather/long')}
          abbreviation={translate('terminology/weather/short')}
        />
      )
    });

    columns.push({
      testId: 'temperature',
      visible: true,
      align: 'right',
      heading: (
        <Abbreviation
          title={translate('terminology/temperature/long')}
          abbreviation={translate('terminology/temperature/short')}
        />
      )
    });

    if (type === 'detailed') {
      columns.push({
        testId: 'feelsLike',
        visible: true,
        align: 'right',
        heading: (
          <Abbreviation
            title={translate('terminology/feelsLike/long')}
            abbreviation={translate('terminology/feelsLike/short')}
          />
        )
      });

      columns.push({
        testId: 'dewPoint',
        visible: true,
        align: 'right',
        heading: (
          <Abbreviation
            title={translate('terminology/dewPoint/long')}
            abbreviation={`${translate('terminology/dewPoint/short')}`}
          />
        )
      });
    }

    columns.push({
      testId: 'precipitation',
      visible: true,
      align: 'right',

      heading: (
        <Abbreviation
          title={`${translate('terminology/precipitation/long')} ${translate('units/millimeter/short')}`}
          // We use a custom short translation here instead of using "terminology/precipitation/short"
          // due to space constraints in the hourly weather table which means the Sami translation
          // must be so short it's hard to understand.
          // The "terminology/precipitation/short" should be used in all other cases
          // where we don't have similar space constraints.
          abbreviation={`${translate('hourlyWeatherTable/precipitation/short')} ${translate('units/millimeter/short')}`}
        />
      )
    });

    if (type === 'detailed' && hasPrecipitationProbability) {
      columns.push({
        testId: 'probabilityOfPrecipitation',
        visible: true,
        align: 'right',
        heading: (
          <Abbreviation
            title={`${translate('terminology/probabilityOfPrecipitation/long')} %`}
            abbreviation={`${translate('terminology/probabilityOfPrecipitation/short')} %`}
          />
        )
      });
    }

    if (hasGust) {
      columns.push({
        testId: 'windWithGust',
        visible: true,
        align: 'right',
        heading: (
          <Abbreviation
            title={`${translate('terminology/windWithGust/long')} ${translate('units/metersPerSecond/short')}`}
            abbreviation={`${translate('terminology/windWithGust/short')} ${translate('units/metersPerSecond/short')}`}
          />
        )
      });
    } else {
      columns.push({
        testId: 'windWithGust',
        visible: true,
        align: 'right',
        heading: `${translate('terminology/windSpeed/long')} ${translate('units/metersPerSecond/short')}`
      });
    }

    if (type === 'simple') {
      columns.push({
        testId: 'windDescription',
        visible: true,
        align: 'left',
        className: 'hourly-weather-table__wind-description',
        heading: (
          <Abbreviation
            title={translate('terminology/windDescription/long')}
            abbreviation={translate('terminology/windDescription/short')}
          />
        )
      });
    }

    if (type === 'detailed') {
      columns.push({
        testId: 'pressure',
        visible: true,
        align: 'right',
        heading: (
          <Abbreviation
            title={`${translate('terminology/pressure/long')} ${translate('units/hectopascal/short')}`}
            abbreviation={`${translate('terminology/pressure/short')} ${translate('units/hectopascal/short')}`}
          />
        )
      });

      columns.push({
        testId: 'humidity',
        visible: true,
        align: 'right',
        heading: (
          <Abbreviation
            title={`${translate('terminology/humidity/long')} %`}
            abbreviation={`${translate('terminology/humidity/short')} %`}
          />
        )
      });

      columns.push({
        testId: 'cloudCover',
        visible: true,
        align: 'right',
        className: 'hourly-weather-table__cloud',
        heading: (
          <Abbreviation
            title={`${translate('terminology/cloudCover/long')} %`}
            abbreviation={`${translate('terminology/cloudCover/short')} %`}
          />
        )
      });

      columns.push({
        testId: 'cloudCoverFog',
        visible: true,
        align: 'right',
        className: 'hourly-weather-table__cloud',
        heading: (
          <Abbreviation
            title={`${translate('terminology/cloudCoverFog/long')} %`}
            abbreviation={`${translate('terminology/cloudCoverFog/short')} %`}
          />
        )
      });

      columns.push({
        testId: 'cloudCoverLow',
        visible: true,
        align: 'right',
        className: 'hourly-weather-table__cloud',
        heading: (
          <Abbreviation
            title={`${translate('terminology/cloudCoverLow/long')} %`}
            abbreviation={`${translate('terminology/cloudCoverLow/short')} %`}
          />
        )
      });

      columns.push({
        testId: 'cloudCoverMiddle',
        visible: true,
        align: 'right',
        className: 'hourly-weather-table__cloud',
        heading: (
          <Abbreviation
            title={`${translate('terminology/cloudCoverMiddle/long')} %`}
            abbreviation={`${translate('terminology/cloudCoverMiddle/short')} %`}
          />
        )
      });

      columns.push({
        testId: 'cloudCoverHigh',
        visible: true,
        align: 'right',
        className: 'hourly-weather-table__cloud',
        heading: (
          <Abbreviation
            title={`${translate('terminology/cloudCoverHigh/long')} %`}
            abbreviation={`${translate('terminology/cloudCoverHigh/short')} %`}
          />
        )
      });
    }

    return columns;
  }, [type, translate, hasGust, hasPrecipitationProbability]);

  const weatherTableRows = useMemo(() => {
    const timeformat = translate('units/timeformat');

    return intervalsGroupedByDay.intervals.map(subInterval => {
      const hourEnd = time.create(subInterval.end).locale(timeLocale);
      const hourStart = time.create(subInterval.start).locale(timeLocale);
      const cells = [];

      cells.push(
        <span className="hourly-weather-table__time">
          {hourEnd.diff(hourStart, 'hours') === 1 ? (
            <Text size="5" tabularNums={true}>
              <time dateTime={hourStart.format(timeformat)}>{hourStart.format('HH')}</time>
            </Text>
          ) : (
            <>
              <Text size="5" tabularNums={true}>
                <time dateTime={hourStart.format(timeformat)}>{hourStart.format('HH')}</time>
                {'–'}
                <time dateTime={hourEnd.format(timeformat)}>{hourEnd.format('HH')}</time>
              </Text>
            </>
          )}
        </span>
      );

      const symbolId = convertSymbolKeyToId(
        isForecastLongInterval(subInterval) ? subInterval.symbolCode.next6Hours : subInterval.symbolCode.next1Hour
      );

      cells.push(
        <span className="hourly-weather-table__weather">
          <WeatherSymbol id={symbolId} />
        </span>
      );

      cells.push(
        <Text size="4" tabularNums={true}>
          <Temperature value={subInterval.temperature.value} decimal={false} renderScreenReaderLabel={false} />
        </Text>
      );

      if (type === 'detailed') {
        cells.push(
          subInterval.feelsLike.value !== undefined ? (
            <Text size="4" tabularNums={true}>
              <Temperature value={subInterval.feelsLike.value} decimal={false} renderScreenReaderLabel={false} />
            </Text>
          ) : (
            ''
          )
        );

        cells.push(
          subInterval.dewPoint ? (
            <Text size="4" tabularNums={true}>
              <Temperature value={subInterval.dewPoint.value} decimal={false} renderScreenReaderLabel={false} />
            </Text>
          ) : (
            ''
          )
        );
      }

      cells.push(
        <Text size="4" tabularNums={true}>
          <MinMaxPrecipitation
            values={subInterval.precipitation}
            displayZero={false}
            displayUnit={false}
            renderScreenReaderLabel={false}
          />
        </Text>
      );

      if (type === 'detailed' && hasPrecipitationProbability) {
        cells.push(
          subInterval.precipitation.probability != null ? (
            <Text size="4" tabularNums={true}>
              <PrecipitationProbability probability={subInterval.precipitation.probability} />
            </Text>
          ) : (
            ''
          )
        );
      }

      cells.push(
        <Text size="4" tabularNums={true}>
          <Wind
            type="wind-and-gust"
            windGust={subInterval.wind.gust}
            value={subInterval.wind.speed}
            displayArrow={true}
            direction={subInterval.wind.direction}
            displayUnit={false}
            decimal={false}
            className="location-weather-table__wind-value"
            screenReaderLabel={getWindSpeedWithDirectionAndGust(
              subInterval.wind.speed,
              subInterval.wind.direction,
              translate,
              subInterval.wind.gust
            )}
            debug={{ caller: 'HourlyWeatherTable', api: 'forecast' }}
          />
        </Text>
      );

      if (type === 'simple') {
        cells.push(<WindDescription {...subInterval.wind} />);
      }

      if (type === 'detailed') {
        cells.push(<Text size="4" tabularNums={true}>{`${subInterval.pressure.value}`}</Text>);
        cells.push(
          subInterval.humidity ? (
            <Text size="4" tabularNums={true}>{`${Math.round(subInterval.humidity.value)}`}</Text>
          ) : (
            ''
          )
        );

        cells.push(
          subInterval.cloudCover ? (
            <Text size="4" tabularNums={true}>
              {subInterval.cloudCover.value}
            </Text>
          ) : (
            ''
          )
        );
        cells.push(
          subInterval.cloudCover ? (
            <Text size="4" tabularNums={true}>
              {subInterval.cloudCover.fog}
            </Text>
          ) : (
            ''
          )
        );
        cells.push(
          subInterval.cloudCover ? (
            <Text size="4" tabularNums={true}>
              {subInterval.cloudCover.low}
            </Text>
          ) : (
            ''
          )
        );
        cells.push(
          subInterval.cloudCover ? (
            <Text size="4" tabularNums={true}>
              {subInterval.cloudCover.middle}
            </Text>
          ) : (
            ''
          )
        );
        cells.push(
          subInterval.cloudCover ? (
            <Text size="4" tabularNums={true}>
              {subInterval.cloudCover.high}
            </Text>
          ) : (
            ''
          )
        );
      }

      return { cells };
    });
  }, [type, intervalsGroupedByDay.intervals, translate, hasPrecipitationProbability, timeLocale]);

  return (
    <div className={classNames('hourly-weather-table')}>
      {intervalsGroupedByDay ? (
        <FluidTable
          columns={weatherTableColumns}
          rows={weatherTableRows}
          stickyFirstColumn={true}
          tableCaption={tableCaption}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default HourlyWeatherTable;
