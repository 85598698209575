import classNames from 'classnames';
import { IPrecipitationPeriod } from '../../model/precipitation';
import { getPrecipitationDisplayValue } from '@nrk/yr-helpers';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { Precipitation } from '../Precipitation/Precipitation';
import './MinMaxPrecipitation.scss';

interface IProps {
  className?: string;
  valueClassName?: string;
  unitClassName?: string;
  dashClassName?: string;
  values: Pick<IPrecipitationPeriod, 'min' | 'max' | 'value'>;
  /**  - defaults to 'automatic'
   *  - 'automatic' - renders one decimal up to 9.9, rounds everything above
   *  - true - renders with one decimal regardless of value
   *  - false - rounds all numbers */
  decimal?: boolean | 'automatic';
  /** - defaults to true
   *  - Add `mm` after the last value */
  displayUnit?: boolean;
  /** - defaults to true
   *  - The entire element is invisible if both displayed values would be 0 */
  displayZero?: boolean;
  /** - defaults to true
   *  - Should be set to false when used in table
   *    where the table header already explains what the value is. */
  renderScreenReaderLabel?: boolean;
}

export const MinMaxPrecipitation = (props: IProps) => {
  const {
    className,
    valueClassName,
    unitClassName,
    dashClassName,
    decimal,
    values: { min, max, value },
    displayUnit,
    renderScreenReaderLabel = true
  } = props;
  let { displayZero = true } = props;

  const translate = useTranslate();

  // Show value instead of min to max if they are identical or if one of them is missing
  if (min == null || max == null || min === max) {
    // Hide the element when displayZero is false but only if the display value is 0
    const { displayValue } = getPrecipitationDisplayValue({ value, decimal });
    const isHidden = displayZero === false && displayValue === 0;

    return (
      <div
        className={classNames('min-max-precipitation', { 'min-max-precipitation--hidden': isHidden }, className)}
        role="text"
      >
        <Precipitation
          valueClassName={valueClassName}
          unitClassName={unitClassName}
          value={value}
          decimal={decimal}
          displayUnit={displayUnit}
          displayZero={displayZero}
          renderScreenReaderLabel={renderScreenReaderLabel}
        />
      </div>
    );
  }

  // Hide the element when displayZero is false but only if both display values are 0
  const { displayValue: minDisplayValue } = getPrecipitationDisplayValue({ value: min, decimal });
  const { displayValue: maxDisplayValue } = getPrecipitationDisplayValue({ value: max, decimal });
  const isHidden = displayZero === false && minDisplayValue === 0 && maxDisplayValue === 0;

  return (
    <div
      className={classNames('min-max-precipitation', { 'min-max-precipitation--hidden': isHidden }, className)}
      role="text"
    >
      {/* We render the screen reader label inside an nrk-sr since aria-label on a div with role=text is ignored by some screen readers. */}
      <span className="nrk-sr">
        {renderScreenReaderLabel && translate('terminology/precipitation/long')}{' '}
        {displayUnit
          ? translate('minMaxPrecipitation/labelWithUnit', { minValue: minDisplayValue, maxValue: maxDisplayValue })
          : translate('minMaxPrecipitation/label', { minValue: minDisplayValue, maxValue: maxDisplayValue })}
      </span>
      <div aria-hidden={true}>
        <Precipitation
          valueClassName={valueClassName}
          unitClassName={unitClassName}
          value={min}
          decimal={decimal}
          // Never display the unit for the first value
          displayUnit={false}
          // Display zero values because `[empty]–2` looks weird
          displayZero={true}
          // Screen reader text is rendered in a `nrk-sr` span above
          renderScreenReaderLabel={false}
        />
        <span className={classNames('min-max-precipitation__dash', dashClassName)} aria-hidden={true}>
          –
        </span>
        <Precipitation
          valueClassName={valueClassName}
          unitClassName={unitClassName}
          value={max}
          decimal={decimal}
          displayUnit={displayUnit}
          // Display zero values because `2–[empty]` looks weird
          displayZero={true}
          // Screen reader text is rendered in a `nrk-sr` span above
          renderScreenReaderLabel={false}
        />
      </div>
    </div>
  );
};
