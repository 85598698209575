import yrTime from '@nrk/yr-time';
import { useAppState } from '../../app/contexts/AppStateContext';
import { createRelativeTimeLabel, TRelativeTimeType } from '../../lib/helpers/time';
import { useTranslate } from '../../lib/hooks/useTranslate';

interface IProps {
  type: TRelativeTimeType;
  time: string;
  relativeTodayOnly?: boolean;
  transform?: 'sentence-case';
}

export function RelativeTime(props: IProps) {
  const { type, time, relativeTodayOnly, transform } = props;

  const { isFirstRender } = useAppState();
  const translate = useTranslate();

  // Use the time string itself as the datetime attribute if the time type includes a timestamp
  const dateTimeAttribute = includesTimestamp(type) ? time : yrTime.create(time).format('YYYY-MM-DD');

  const dateLabel = createRelativeTimeLabel({
    type,
    time,
    isFirstRender,
    relativeTodayOnly,
    translate,
    transform
  });

  return <time dateTime={dateTimeAttribute}>{dateLabel}</time>;
}

function includesTimestamp(type: TRelativeTimeType) {
  return type === 'relative-date-with-time-short' || type === 'relative-date-with-time-long';
}
